import { Link } from "react-router-dom"

export default function Regresar({ link, des }) {
    return (
        <div className="contenido_back">
            <Link to={ link }></Link>
            <img src="/images/tierra/arrow.svg" alt="Flecha atrás" title="Flecha atrás"/>
            <div className="contenido_back__des">{ des }</div>
        </div>
    )
}