'use client'

const menu = () => { document.querySelector('.sidebar').classList.toggle('active') }

export function NavbarButton() {
    document.onkeydown = function(event) {
        event = event || window.Event;
        if(event.keyCode == 27)
            if(document.querySelector('.sidebar').classList.contains('active'))
                document.querySelector('.sidebar').classList.remove('active');
    }
    
    return ( <div onClick={ menu } className="navbar_options__menu"><img src="/images/header/bars.svg" alt="Menu Icon" title="Menu Icon"/></div> )
}

export function SidebarButton() {
    return ( <img onClick={ menu } src="/images/header/close.svg" alt="Close icon" title="Close icon" className="sidebar_close"></img> )
}

export function SidebarSobrante() {
    return ( <div onClick={ menu } className="sidebar_sobrante"></div> )
}