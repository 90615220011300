import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"
import { NavHashLink } from "react-router-hash-link";
import { NavbarButton, SidebarButton, SidebarSobrante } from "./NavigationButtons"
import "./../styles/global.css"

export function NavbarNew({ engLink }) {
    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? document.querySelector('.navbar').classList.add('fixed') : document.querySelector('.navbar').classList.remove('fixed');
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    const navigate = useNavigate();

    const handleChange = e => {
        navigate(engLink)
    }
    
    return (
        <div className="navbar section">
            <div className="content">
            <Link to="/" title="Página principal" className="navbar_logo"><img src="/images/header/logo.svg" alt="Logo Gran Acuario Mazatlán" title="Logo Gran Acuario Mazatlán"/></Link>
                <div className="navbar_options">
                    <Link to="/habitats" title="Hábitats" className="navbar_options__el">Animales</Link>
                    <Link to="/entradas" title="Entradas" className="navbar_options__el">Entradas</Link>
                    <a href="https://tienda.granacuario.com/" target="_blank" title="Entradas" className="navbar_options__el">Souvenirs</a>
                    <a href="https://tickets.granacuario.com/" title="tickets acuario" target="_blank" className="navbar_options__tickets">{/*<img src="/images/header/entradas.svg" alt="Tickets Icon"/>*/}TICKETS</a>
                    <a href="https://www.instagram.com/granacuariomazatlan/" target="_blank" title="instagram" className="navbar_social"><img src="/images/instagram.svg" alt="Instagram logo" title="Instagram logo"/></a>
                    <a href="https://www.facebook.com/granacuariomazatlan" target="_blank" title="facebook" className="navbar_social"><img src="/images/facebook.svg" alt="Facebook logo" title="Facebook logo"/></a>
                    <a href="https://www.youtube.com/@granacuariomazatlan" target="_blank" title="youtube" className="navbar_social"><img src="/images/youtube.svg" alt="Youtube logo" title="Youtube logo"/></a>
                    <a href="https://x.com/GranAcuarioMzt" target="_blank" title="x" className="navbar_social"><img src="/images/x.svg" alt="X logo" title="X logo"/></a>
                    <a href="https://www.tiktok.com/@granacuariomazatlan" target="_blank" title="tiktok" className="navbar_social"><img src="/images/tiktok.svg" alt="Tiktok logo" title="Tiktok logo"/></a>
                    <div className="lan_container">
                        <img className="lan_container_img" src="/images/header/lan.png" alt="Lenguaje icon" title="Lenguaje icon"/>
                        <select onChange={ handleChange }>
                            <option selected>Español</option>
                            <option value="/eng/">English</option>
                        </select>
                    </div>
                    <NavbarButton/>
                </div>
            </div>
            <div className="content_mobile new">
                <a href="https://tickets.granacuario.com/" title="tickets" target="_blank" className="content_mobile__el">TICKETS</a>
            </div>
        </div>
    )
}

export function Navbar({ engLink }) {
    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? document.querySelector('.navbar').classList.add('fixed') : document.querySelector('.navbar').classList.remove('fixed');
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    const navigate = useNavigate();

    const handleChange = e => {
        navigate(engLink)
    }
    
    return (
        <div className="navbar section">
            <div className="content">
            <Link to="/" title="Página principal" className="navbar_logo"><img src="/images/header/logo.svg" alt="Logo Gran Acuario Mazatlán" title="Logo Gran Acuario Mazatlán"/></Link>
                <div className="navbar_options">
                    <Link to="/habitats" title="Hábitats" className="navbar_options__el">Animales</Link>
                    <Link to="/entradas" title="Entradas" className="navbar_options__el">Entradas</Link>
                    <Link to="/educacion" title="Educación" className="navbar_options__el">Educación</Link>
                    <a href="https://tickets.granacuario.com/" title="tickets" target="_blank" className="navbar_options__tickets">{/*<img src="/images/header/entradas.svg" alt="Tickets Icon"/>*/}TICKETS</a>
                    <div className="lan_container">
                        <img className="lan_container_img" src="/images/header/lan.png" alt="Lenguaje icon" title="Lenguaje icon"/>
                        <select onChange={ handleChange }>
                            <option label="Español" selected>Español</option>
                            <option label="English" value="/eng/">English</option>
                        </select>
                    </div>
                    <NavbarButton/>
                </div>
            </div>
            <div className="content_mobile">
                <Link to="#" title="Tienda" className="content_mobile__el">TIENDA <span>(próximamente)</span></Link>
                <a href="https://tickets.granacuario.com/" title="tickets" target="_blank" className="content_mobile__el">TICKETS</a>
            </div>
        </div>
    )
}

export function NavbarEngNew({ espLink }) {
    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? document.querySelector('.navbar').classList.add('fixed') : document.querySelector('.navbar').classList.remove('fixed');
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    const navigate = useNavigate();

    const handleChange = e => {
        navigate(espLink)
    }
    
    return (
        <div className="navbar section">
            <div className="content">
            <Link to="/eng/" title="Página principal" className="navbar_logo"><img src="/images/header/logo.svg" alt="Logo Gran Acuario Mazatlán" title="Logo Gran Acuario Mazatlán"/></Link>
                <div className="navbar_options">
                    <Link to="/habitats" title="Hábitats" className="navbar_options__el">Habitats</Link>
                    <Link to="/entradas" title="Entradas" className="navbar_options__el">Ticketing</Link>
                    <a href="https://tienda.granacuario.com/" target="_blank" title="Entradas" className="navbar_options__el">Souvenirs</a>
                    <a href="https://tickets.granacuario.com/" title="tickets acuario" target="_blank" className="navbar_options__tickets">{/*<img src="/images/header/entradas.svg" alt="Tickets Icon"/>*/}TICKETS</a>
                    <a href="https://www.instagram.com/granacuariomazatlan/" target="_blank" title="instagram" className="navbar_social"><img src="/images/instagram.svg" alt="Instagram logo" title="Instagram logo"/></a>
                    <a href="https://www.facebook.com/granacuariomazatlan" target="_blank" title="facebook" className="navbar_social"><img src="/images/facebook.svg" alt="Facebook logo" title="Facebook logo"/></a>
                    <a href="https://www.youtube.com/@granacuariomazatlan" target="_blank" title="youtube" className="navbar_social"><img src="/images/youtube.svg" alt="Youtube logo" title="Youtube logo"/></a>
                    <a href="https://x.com/GranAcuarioMzt" target="_blank" title="x" className="navbar_social"><img src="/images/x.svg" alt="X logo" title="X logo"/></a>
                    <a href="https://www.tiktok.com/@granacuariomazatlan" target="_blank" title="tiktok" className="navbar_social"><img src="/images/tiktok.svg" alt="Tiktok logo" title="Tiktok logo"/></a>
                    <div className="lan_container">
                        <img className="lan_container_img" src="/images/header/lan.png" alt="Lenguaje icon" title="Lenguaje icon"/>
                        <select onChange={ handleChange }>
                            <option selected>English</option>
                            <option value="/">Español</option>
                        </select>
                    </div>
                    <NavbarButton/>
                </div>
            </div>
            <div className="content_mobile new">
                <a href="https://tickets.granacuario.com/" title="tickets" target="_blank" className="content_mobile__el">TICKETS</a>
            </div>
        </div>
    )
}

export function NavbarEng({ espLink }) {
    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? document.querySelector('.navbar').classList.add('fixed') : document.querySelector('.navbar').classList.remove('fixed');
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    const navigate = useNavigate();

    const handleChange = e => {
        navigate(espLink)
    }

    return (
        <div className="navbar section">
            <div className="content">
            <Link to="/eng/" title="Página en inglés" className="navbar_logo"><img src="/images/header/logo.svg" alt="Logo Gran Acuario Mazatlán" title="Logo Gran Acuario Mazatlán"/></Link>
                <div className="navbar_options">
                    <Link to="/eng/habitats" title="Habitats" className="navbar_options__el">Habitats</Link>
                    <Link to="/eng/ticketing" title="Ticketing" className="navbar_options__el">Ticketing</Link>
                    <Link to="/eng/education" title="Education" className="navbar_options__el">Education</Link>
                    <a href="https://tickets.granacuario.com/" title="tickets" target="_blank" className="navbar_options__tickets">{/*<img src="/images/header/entradas.svg" alt="Tickets Icon"/>*/}TICKETS</a>
                    <div className="lan_container">
                        <img className="lan_container_img" src="/images/header/lan.png" alt="Lenguaje icon" title="Lenguaje icon"/>
                        <select onChange={ handleChange }>
                            <option selected>English</option>
                            <option>Español</option>
                        </select>
                    </div>
                    <NavbarButton/>
                </div>
            </div>
            <div className="content_mobile">
                <Link to="#" title="Store" className="content_mobile__el">STORE <span>(soon)</span></Link>
                <a href="https://tickets.granacuario.com/" title="tickets" target="_blank" className="content_mobile__el">TICKETS</a>
            </div>
        </div>
    )
}

export function SidebarNew({ engLink }) {
    return (
        <div className="sidebar">
            <SidebarSobrante/>
            <div className="sidebar_container">
                <SidebarButton/>
                <div className="sidebar_buttons">
                    <a href="https://tickets.granacuario.com/" title="tickets" target="_blank" className="sidebar_entradas">TICKETS</a>
                </div>
                <div className="sidebar_language">
                    <Link to="" title="Español" className="sidebar_language__el active">ESPAÑOL</Link>
                    <Link to={ engLink } title="English" className="sidebar_language__el">ENGLISH</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/entradas" title="Entradas" className="sidebar_con__t1">ENTRADAS</Link></div>
                <div className="sidebar_con solo"><a href="https://tienda.granacuario.com/" target="_blank" title="Entradas" className="sidebar_con__t1">SOUVENIRS</a></div>
                <div className="sidebar_con">
                    <Link to="/habitats" title="Habitats" className="sidebar_con__t1">ANIMALES</Link>
                    <Link to="/habitats/tierra" title="Tierra" className="sidebar_con__t2">Tierra</Link>
                    <Link to="/habitats/costa" title="Costa" className="sidebar_con__t2">Costa</Link>
                    <Link to="/habitats/mar" title="Mar" className="sidebar_con__t2">Mar</Link>
                </div>
                <div className="sidebar_con">
                    <Link to="/visita" title="Planifica tu visita" className="sidebar_con__t1">PLANIFICA TU VISITA</Link>
                    <NavHashLink to="/visita#tarifas" title="Tarifas" className="sidebar_con__t2">Tarifas y horarios</NavHashLink>
                    <NavHashLink to="/visita#recomendaciones" title="Recomendaciones" className="sidebar_con__t2">Recomendaciones</NavHashLink>
                    <NavHashLink to="/visita#plano" title="Plano" className="sidebar_con__t2">Plano del acuario</NavHashLink>
                </div>
                <div className="sidebar_con">
                    <Link to="/actividades" title="Actividades" className="sidebar_con__t1">ACTIVIDADES</Link>
                    <Link to="/actividades/fiestas" title="Fiestas" className="sidebar_con__t2">Eventos sociales</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/grupos" title="Grupos" className="sidebar_con__t1">GRUPOS</Link></div>
                <div className="sidebar_con solo"><Link to="/grupos" title="Nosotros" className="sidebar_con__t1">NOSOTROS</Link></div>
                <div className="sidebar_con solo"><Link to="/faq" title="Preguntas frecuentes" className="sidebar_con__t1">PREGUNTAS FRECUENTES</Link></div>
                <div className="sidebar_con solo"><a href="tel:6696890501" title="Llamar" className="sidebar_con__t1">OBJETOS PERDIDOS</a></div>
                <div className="sidebar_con solo"><Link to="/educacion" title="Educación" className="sidebar_con__t1">EDUCACIÓN</Link></div>
                <div className="sidebar_con solo"><Link to="/investigacion" title="Investigación" className="sidebar_con__t1">INVESTIGACIÓN</Link></div>
                {/*<div className="sidebar_con solo"><Link to="#" target="_blank" className="sidebar_con__t1">TIENDA EN LÍNEA</Link></div>*/}
                <div className="sidebar_con solo"><Link to="/contacto" title="Contacto" className="sidebar_con__t1">CONTACTO</Link></div>
            </div>
        </div>
    )
}

export function Sidebar({ engLink }) {
    return (
        <div className="sidebar">
            <SidebarSobrante/>
            <div className="sidebar_container">
                <SidebarButton/>
                <div className="sidebar_buttons">
                    <Link to="#" title="Tienda" className="sidebar_tienda">TIENDA <span>(próximamente)</span></Link>
                    <a href="https://tickets.granacuario.com/" title="tickets" target="_blank" className="sidebar_entradas">TICKETS</a>
                </div>
                <div className="sidebar_language">
                    <Link to="" title="Español" className="sidebar_language__el active">ESPAÑOL</Link>
                    <Link to={ engLink } title="Inglés" className="sidebar_language__el">ENGLISH</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/entradas" className="sidebar_con__t1">ENTRADAS</Link></div>
                <div className="sidebar_con">
                    <Link to="/habitats" title="Habitsts" className="sidebar_con__t1">ANIMALES</Link>
                    <Link to="/habitats/tierra" title="Tierra" className="sidebar_con__t2">Tierra</Link>
                    <Link to="/habitats/costa" title="Costa" className="sidebar_con__t2">Costa</Link>
                    <Link to="/habitats/mar" title="Mar" className="sidebar_con__t2">Mar</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/educacion" title="Educación" className="sidebar_con__t1">EDUCACIÓN</Link></div>
                <div className="sidebar_con solo"><Link to="/investigacion" title="Investigación" className="sidebar_con__t1">INVESTIGACIÓN</Link></div>
                <div className="sidebar_con">
                    <Link to="/actividades" title="Actividades" className="sidebar_con__t1">ACTIVIDADES</Link>
                    <Link to="/actividades/fiestas" title="Fiestas" className="sidebar_con__t2">Eventos sociales</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/grupos" title="Grupos" className="sidebar_con__t1">GRUPOS</Link></div>
                {/*<div className="sidebar_con solo"><Link to="#" target="_blank" className="sidebar_con__t1">TIENDA EN LÍNEA</Link></div>*/}
                <div className="sidebar_con">
                    <Link to="/visita" title="Planifica tu visita" className="sidebar_con__t1">PLANIFICA TU VISITA</Link>
                    <NavHashLink to="/visita#tarifas" title="Tarifas" className="sidebar_con__t2">Tarifas y horarios</NavHashLink>
                    <NavHashLink to="/visita#recomendaciones" title="Recomendaciones" className="sidebar_con__t2">Recomendaciones</NavHashLink>
                    <NavHashLink to="/visita#plano" title="Plano" className="sidebar_con__t2">Plano del acuario</NavHashLink>
                </div>
                <div className="sidebar_con solo"><Link to="/faq" title="Preguntas frecuentes" className="sidebar_con__t1">Preguntas frecuentes</Link></div>
                <div className="sidebar_con solo"><a href="tel:6696890501" title="Llamar" className="sidebar_con__t1">Objetos perdidos</a></div>
                <div className="sidebar_con solo"><Link to="/contacto" title="Contacto" className="sidebar_con__t1">Contacto</Link></div>
            </div>
        </div>
    )
}

export function SidebarEng({ espLink }) {
    return (
        <div className="sidebar">
            <SidebarSobrante/>
            <div className="sidebar_container">
                <SidebarButton/>
                <div className="sidebar_buttons">
                    <a href="https://tickets.granacuario.com/" title="tickets" target="_blank" className="sidebar_entradas">TICKETS</a>
                </div>
                <div className="sidebar_language">
                    <Link to={ espLink } title="Español" className="sidebar_language__el">ESPAÑOL</Link>
                    <Link to="#" title="Inglés" className="sidebar_language__el active">ENGLISH</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/eng/ticketing" title="Ticketing" className="sidebar_con__t1">TICKETING</Link></div>
                <div className="sidebar_con">
                    <Link to="/eng/habitats" title="Habitats" className="sidebar_con__t1">HABITATS</Link>
                    <Link to="/eng/habitats/land" title="Land" className="sidebar_con__t2">Land</Link>
                    <Link to="/eng/habitats/coast" title="Coast" className="sidebar_con__t2">Coast</Link>
                    <Link to="/eng/habitats/sea" title="Sea" className="sidebar_con__t2">Sea</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/eng/education" title="Education" className="sidebar_con__t1">EDUCATION</Link></div>
                <div className="sidebar_con solo"><Link to="/eng/research" title="Research" className="sidebar_con__t1">RESEARCH</Link></div>
                <div className="sidebar_con">
                    <Link to="/eng/activities" title="Activities" className="sidebar_con__t1">ACTIVITIES</Link>
                    <Link to="/eng/activities/social-events" title="Social events" className="sidebar_con__t2">Social events</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/eng/groups" title="Groups" className="sidebar_con__t1">GROUPS</Link></div>
                {/*<div className="sidebar_con solo"><Link to="#" target="_blank" className="sidebar_con__t1">TIENDA EN LÍNEA</Link></div>*/}
                <div className="sidebar_con">
                    <Link to="/eng/visit" title="Visit" className="sidebar_con__t1">PLAN YOUR VISIT</Link>
                    <NavHashLink to="/visit#tarifas" title="Tarifas" className="sidebar_con__t2">Rates and Operating Hours</NavHashLink>
                    <NavHashLink to="/visit#recomendaciones" title="Recomendaciones" className="sidebar_con__t2">Recommendations</NavHashLink>
                    <NavHashLink to="/visit#plano" title="Plano" className="sidebar_con__t2">Plano del acuario</NavHashLink>
                </div>
                <div className="sidebar_con solo"><Link to="/eng/faq" title="FAQ" className="sidebar_con__t1">FAQs</Link></div>
                <div className="sidebar_con solo"><Link to="/eng/contact" title="Contact" className="sidebar_con__t1">CONTACT US</Link></div>
            </div>
        </div>
    )
}

export function ExhibitionLink({ linkDes, path, classes="" }) {
    return ( <Link to={ path } title="Exhibición" class={ classes }>{ linkDes }</Link> )
}