'use client'
import entradas from "./../styles/entradas.module.css"
import entradasNew from "./../styles/entradasNew.module.css"
import cx from "classnames"
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion"

export function AccordionItemEntradas({ title, price, des, link }) {
    return (
        <AccordionItem className={ cx( entradas.accordion_item, entradas.accordion_entradas_item) }>
            <AccordionItemHeading className={ cx(entradas.accordion_heading, entradas.accordion_heading_entradas) }>
                <AccordionItemButton className={ cx(entradas.accordion_button, entradas.accordion_button_entradas) }>
                    <div className={ entradas.accordion_title }>{ title }</div>
                    { price != "" && <div className={ entradas.accordion_price }>${ price }</div> }
                    <div className={ entradas.accordion_icon }><img src="/images/entradas/info.svg" alt="info icon" title="info icon"/></div>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={ entradas.accordion_panel }>
                { des } 
                { link && <a href={ "mailto:" + link }>{ link }</a> }
            </AccordionItemPanel>
        </AccordionItem>
    )
}

export function AccordionItemEntradasND({ title, price }) {
    return (
        <AccordionItem className={ cx( entradas.accordion_item, entradas.accordion_entradas_item) }>
            <AccordionItemHeading className={ cx(entradas.accordion_heading, entradas.accordion_heading_entradas) }>
                <AccordionItemButton className={ cx(entradas.accordion_button, entradas.accordion_button_entradas) }>
                    <div className={ entradas.accordion_title }>{ title }</div>
                    <div className={ entradas.accordion_price }>${ price }</div>
                </AccordionItemButton>
            </AccordionItemHeading>
        </AccordionItem>
    )
}

export function AccordionItemEspeciales({ title, price, des }) {
    return (
        <AccordionItem className={ cx( entradas.accordion_item, entradasNew.accordion_especiales_item) }>
            <AccordionItemHeading className={ cx(entradas.accordion_heading, entradas.accordion_heading_especiales) }>
                <AccordionItemButton className={ cx(entradas.accordion_button, entradasNew.accordion_button_especiales) }>
                    <div className={ entradas.accordion_title }>{ title }</div>
                    { price != "" && <div className={ entradas.accordion_price }>${ price }</div> }
                    <div className={ entradas.accordion_icon }><img src="/images/entradas/info.svg" alt="info icon" title="info icon"/></div>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={ entradas.accordion_panel }>
                { des }
            </AccordionItemPanel>
        </AccordionItem>
    )
}

export function AccordionItemEventos({ expanded, uuid, title, subtitle="", price="", des }) {

    const separate = des.includes("*");
    const textos = des.split("*");
    const textosList = textos.map((texto, index) => <div key={ index }>{ texto }</div>);

    return (
        <AccordionItem uuid={ uuid } className={ cx( entradas.accordion_item, entradas.accordion_eventos_item) }>
            <AccordionItemHeading className={ cx(entradas.accordion_heading, entradas.accordion_heading_eventos) }>
                <AccordionItemButton className={ cx(entradas.accordion_button, entradas.accordion_button_eventos) }>
                    <div className={ entradas.accordion_title }>{ title } <span>{ subtitle }</span></div>
                    <div className={ entradas.accordion_price }>{ price != "" ? "$ " + price : "" }</div>
                    <div className={ entradas.accordion_icon }><img src="/images/entradas/info.svg" alt="info icon" title="info icon"/></div>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={ entradas.accordion_panel }>
                { separate
                    ? textosList
                    : des
                 }
            </AccordionItemPanel>
        </AccordionItem>
    )
}