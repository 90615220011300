import { Navbar, NavbarNew, Sidebar, SidebarNew } from "../components/Navigation"
import Header from "../components/Header"
import { CioDescription, Element } from "../components/HabitatElements"
import Footer from "../components/Footer"
import { Link } from "react-router-dom"
import "./../styles/exhibicionElement.css"
import { MarHabitats } from "../array/habitats"

export default function Mar() {
    return (
        <>
            <NavbarNew engLink={ "/eng/habitats/sea" }/>
            <SidebarNew engLink={ "/eng/habitats/sea" }/>
            <Header routeName="mar" altName="Niño mirando pecera" headerTitle="MAR"/>

            <div className="contenido">
                <CioDescription 
                    des={ [
                        'Existe un solo océano global que cubre el 70% del planeta.',
                        'A pesar de ser tan importante para nuestra supervivencia conocemos muy poco del océano y sus profundidades, hay mucho por explorar y conocer.',
                    ] }
                />
                <div className="contenido_ops">
                    {MarHabitats.map((habitat) => (
                        <Element link={ habitat.link } nombre={ habitat.nombre } key={ habitat.nombre }/>
                    ))}
                </div>
                <div className="contenido_back"><Link to="/habitats"></Link><img src="/images/tierra/arrow.svg" alt="Flecha atrás"/>Hábitats</div>
            </div>

            <Footer/>
        </>
    )
}