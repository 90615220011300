import { NavbarNew, SidebarNew } from "../components/Navigation";
import Header from "../components/Header";
import Footer from "../components/Footer";
import entradas from "./../styles/entradasNew.module.css"
import cx from "classnames"
import { Accordion } from "react-accessible-accordion"
import { AccordionItemEntradas, AccordionItemEntradasND, AccordionItemEspeciales } from "../components/AccordionItem"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

export default function EntradasNew() {
    // Entradas de texto
    const entradas_title = useRef(null);
    const entradas_sep = useRef(null);
    const entradas_des = useRef(null);
    useEffect(() => { gsap.from(entradas_title.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.entradas_title_con_title' }); }, []);
    useEffect(() => { gsap.from(entradas_sep.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.entradas_title_con_sep' }); }, []);
    useEffect(() => { gsap.from(entradas_des.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.entradas_title_con_p' }); }, []);
    const membresias_title = useRef(null);
    const membresias_sep = useRef(null);
    const membresias_des = useRef(null);
    useEffect(() => { gsap.from(membresias_title.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.membresias_title_ref' }); }, []);
    useEffect(() => { gsap.from(membresias_sep.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.membresias_sep_ref' }); }, []);
    useEffect(() => { gsap.from(membresias_des.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.membresias_des_ref' }); }, []);

    // Animación mantarraya
    const mantarraya = useRef(null);
    const timeline_mantarraya = useRef();
    useEffect(() => {
        timeline_mantarraya.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.entradas_section',
                start: '25% bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_mantarraya.current.to(mantarraya.current, {x:-80, y:-120, duration: 3});
    });

    // Entradas bloque
    const entradas_img = useRef(null);
    const entradas_des_ref = useRef(null);
    useEffect(() => { gsap.to(entradas_img.current, { duration: 1.5, x: 0, opacity: 1, scrollTrigger: '.entradas_img_ref' }); }, []);
    useEffect(() => { gsap.to(entradas_des_ref.current, { duration: 1.5, x: 0, opacity: 1, scrollTrigger: '.entradas_des_ref' }); }, []);
    const membresias_img_ref = useRef(null);
    const membresias_des_ref = useRef(null);
    useEffect(() => { gsap.to(membresias_img_ref.current, { duration: 1.5, x: 0, opacity: 1, scrollTrigger: '.membresias_img_ref' }); }, []);
    useEffect(() => { gsap.to(membresias_des_ref.current, { duration: 1.5, x: 0, opacity: 1, scrollTrigger: '.membresias_des_ref' }); }, []);
   
    return (
        <>
            <NavbarNew engLink={ "/eng/ticketing" }/>
            <SidebarNew engLink={ "/eng/ticketing" }/>
            <Header routeName="entradas" altName="Niño mirando pecera" headerTitle="notitle" titleClasses="default"/>

            {/* Entradas title */}
            <div className={ cx(entradas.entradas_title, 'section', 'entradas_section') }>
                <div className={ entradas.entradas_mantarraya }>
                    <picture>
                        <source type="image/webp" srcSet="/images/entradas/mantarraya@600.webp" media="(min-width:600px)"/>
                        <source type="image/png" srcSet="/images/entradas/mantarraya@600.png" media="(min-width:600px)"/>
                        <img ref={ mantarraya } className={ cx(entradas.mantarraya, 'mantarraya') } src="/images/entradas/mantarraya@300.png" srcSet="/images/entradas/mantarraya@300.webp" alt="Mantarraya" title="Mantarraya"/>
                    </picture>
                </div>
                <picture>
                    <source type="image/webp" srcSet="/images/entradas/momentosMemorables@600.webp" media="(min-width: 399px)"/>
                    <source type="image/png" srcSet="/images/entradas/momentosMemorables@600.png" media="(min-width: 399px)"/>
                    <img className={ entradas.entradas_title_bg } src="/images/entradas/momentosMemorables@400.png" srcSet="/images/entradas/momentosMemorables@400.webp" alt="Momento memorables del mundo marino" title="Momento memorables del mundo marino"/>
                </picture>
                <div className={ cx(entradas.entradas_title_content, 'content') }>
                    <div className={ entradas.entradas_title_con }>
                        <div ref={ entradas_title } className={ cx(entradas.entradas_title_con_title, 'title', 'entradas_title_con_title') }>ENTRADAS</div>
                        <span ref={ entradas_sep } className={ cx(entradas.entradas_title_con_sep, 'entradas_title_con_sep') }></span>
                        <div ref={ entradas_des } className={ cx(entradas.entradas_title_con_p, 'entradas_title_con_p') }>Puedes comprar tus entradas online, sin filas<br/>y con puerta de acceso especial o directamente<br/>en la taquilla.</div>
                    </div>
                </div>
            </div>

            {/* Entradas opciones */}
            <div className={ cx(entradas.entradas, 'section') }>
                <div className={ cx(entradas.entradas_content) }>
                    <div ref={ entradas_img } className={ cx(entradas.entradas_img, 'entradas_img_ref') }>
                        <picture>
                            <source type="image/webp" srcSet="/images/entradas/pecera@1100.webp" media="(max-width:1199px) and (min-width: 1100px)"/>
                            <source type="image/png" srcSet="/images/entradas/pecera@1100.png" media="(max-width:1199px) and (min-width: 1100px)"/>
                            <source type="image/webp" srcSet="/images/entradas/pecera@1000.webp" media="(max-width:1099px) and (min-width: 1000px)"/>
                            <source type="image/png" srcSet="/images/entradas/pecera@1000.png" media="(max-width:1099px) and (min-width: 1000px)"/>
                            <source type="image/webp" srcSet="/images/entradas/pecera@900.webp" media="(max-width:999px) and (min-width: 900px)"/>
                            <source type="image/png" srcSet="/images/entradas/pecera@900.png" media="(max-width:999px) and (min-width: 900px)"/>
                            <source type="image/webp" srcSet="/images/entradas/pecera@899.webp" media="(max-width:899px) and (min-width: 600px)"/>
                            <source type="image/png" srcSet="/images/entradas/pecera@899.png" media="(max-width:899px) and (min-width: 600px)"/>
                            <source type="image/webp" srcSet="/images/entradas/pecera@960.webp" media="(min-width:600px)"/>
                            <source type="image/png" srcSet="/images/entradas/pecera@960.png" media="(min-width:600px)"/>
                            <source type="image/webp" srcSet="/images/entradas/pecera@600.webp" media="(max-width:599px) and (min-width: 400px)"/>
                            <source type="image/png" srcSet="/images/entradas/pecera@600.png" media="(max-width:599px) and (min-width: 400px)"/>
                            <img src="/images/entradas/pecera@400.png" srcSet="/images/entradas/pecera@400.webp" alt="Pecera" title="Pecera"/>
                        </picture>
                    </div>
                    <div ref={ entradas_des_ref } className={ cx(entradas.entradas_options, 'entradas_des_ref') }>
                        <div className={ entradas.entradas_options_con }>
                            <Accordion className={ entradas.accordion_entradas }>
                                <AccordionItemEntradas title="Adulto" price="470" des="Con tu boleto de entrada podrás conocer los más de 30 hábitats que se encuentran en nuestro impresionante edificio y sus alrededores. Así mismo tendrás la oportunidad de presenciar las exhibición de aves, buceo, lobos marinos y podrás visitar el hábitat de los pingüinos de Humboldt."/>
                                <AccordionItemEntradas title="Adulto local" price="380" des="Con tu boleto de entrada podrás conocer los más de 30 hábitats que se encuentran en nuestro impresionante edificio y sus alrededores. Así mismo tendrás la oportunidad de presenciar las exhibición de aves, buceo, lobos marinos y podrás visitar el hábitat de los pingüinos de Humboldt. El adulto deberá presentar credencial del INE vigente con domicilio en Mazatlán."/>
                                <AccordionItemEntradas title="Niño" price="370" des="Desde los 4 años hasta los 11 años. Menores de 4 años entrada gratuita presentando documento que acredite edad."/>
                                <AccordionItemEntradas title="Niño local" price="330" des="De 4 a 11 años. Menores de 4 años entrada gratuita presentando documento que acredite edad. El adulto que lo acompañe deberá presentar credencial del INE vigente con domicilio en Mazatlán."/>
                                <AccordionItemEntradas title="Mayores 65" price="360" des="Mayores de 65 años, pensionistas y/o jubilados. Presentar obligatoriamente documento que lo acredite."/>
                                <AccordionItemEntradasND title="Discapacidad" price="0"/>
                                <AccordionItemEntradas title="Grupos" price="" des="20 personas o más. Reservar en: " link="ventasgrupos@granacuario.com"/>
                            </Accordion>
                            <a className={ cx(entradas.entradas_options_btn) } href="https://tickets.granacuario.com/" target="_blank" title="Comprar entradas">Comprar entradas</a>
                            <div className={ cx(entradas.entradas_options_t1 ) }>Nuestro horario es de 10:00 am a 6:00pm</div>
                            <div className={ cx(entradas.entradas_options_t2 ) }>Cierre de taquillas a las 4:00pm</div>
                            <div className={ cx(entradas.entradas_options_t3 ) }>Para tarifas especiales, pregunta en taquilla o al correo <a href="mailto:ventasgrupos@granacuario.com">ventasgrupos@granacuario.com</a></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Membresías opciones */}
            <div className={ cx(entradas.membresias, 'section') }>
                <div className={ entradas.cotorro_con }><img className={ entradas.cotorro } src="/images/entradas/cotorro.png" srcSet="/images/entradas/cotorro.webp" alt="Cotorro" title="Cotorro"/></div>
                <div className={ cx(entradas.membresias_content, 'content') }>
                    <div className={ entradas.entradas_title_con }>
                        <div ref={ membresias_title } className={ cx(entradas.entradas_title_con_title, 'title', 'membresias_title_ref') }>MEMBRESÍAS</div>
                        <span ref={ membresias_sep } className={ cx(entradas.entradas_title_con_sep, 'membresias_sep_ref') }></span>
                        <div ref={ membresias_des } className={ cx(entradas.entradas_title_con_p, 'membresias_des_ref') }>Disfruta todo el año de la experiencia<br/>Gran Acuario Mazatlán.</div>
                    </div>
                    <div className={ entradas.entradas_membresias }>
                        <div ref={ membresias_img_ref } className={ cx(entradas.entradas_membresias_img, 'membresias_img_ref') }>
                            <picture>
                                <source type="image/webp" srcSet="/images/entradas/membresias@750.webp" media="(min-width:400px)"/>
                                <source type="image/webp" srcSet="/images/entradas/membresias@750.png" media="(min-width:400px)"/>
                                <img src="/images/entradas/membresias@400.png" srcSet="/images/entradas/membresias@400.webp" alt="Membresias" title="Membresias"/>
                            </picture>
                        </div>
                        <div ref={ membresias_des_ref } className={ cx(entradas.entradas_membresias_con, 'membresias_des_ref') }>
                            <img className={ entradas.entradas_membresias_bg } src="/images/entradas/background.svg" alt="Background" title="Background"/>
                            <div className={ entradas.entradas_membresias_con_des }>
                                <Accordion>
                                    <AccordionItemEspeciales title="Pase anual adulto" price="1,500" des="El pase individual te permitirá ingresar al Gran Acuario Mazatlán Mar de Cortés el número de veces que desees durante 365 días del año."/>
                                    <AccordionItemEspeciales title="Pase anual niño" price="1,000" des="Mismos beneficios que el pase anual pero para niños de 4 a 11 años."/>
                                </Accordion>
                                <div className={ entradas.entradas_membresias_con_t }>Favor de presentar identificación oficial al ingresar con su pase anual.</div>
                                <a className={ entradas.entradas_membresias_con_btn } href="https://tickets.granacuario.com/1083-pase-anual-y-membresia" target="_blank" title="Comprar membresías">Comprar membresías</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer/>

            {/* SEO */}
            <div className="seo_container">
                <h1>Compra tus Boletos para el Gran Acuario Mazatlán</h1>
                <h2>Entradas Generales y Precios Especiales</h2>
                <h2>Planea tu Visita Hoy Mismo</h2>
                <h3>Descuentos para Estudiantes, Grupos y Familias</h3>
            </div>
        </>
    )
}