import { Navbar, NavbarNew, Sidebar, SidebarNew } from "../components/Navigation"
import Footer from "../components/Footer"
import { CioDescription, Element } from "../components/HabitatElements"
import Header from "../components/Header"
import { Link } from "react-router-dom"
import "./../styles/exhibicionElement.css"
import { CostaHabitats } from "../array/habitats"

export default function Costa() {
    return (
        <>
            <NavbarNew engLink={ "/eng/habitats/coast" }/>
            <SidebarNew engLink={ "/eng/habitats/coast" }/>
            <Header routeName="costa" altName="Niño mirando pecera" headerTitle="COSTA"/>

            <div className="contenido">
                <CioDescription 
                    des={ [
                        'La costa: donde mar y tierra se encuentran, es un sitio dinámico y cambiante. Lo constituyen diversos ecosistemas como la zona intermareal, el manglar, las marismas y humedales, los pastos marinos, arrecifes, entre otros.',
                        'La costa es una de las zonas de mayor abundancia en formas de vida en el océano.',
                        'Por ser tan cercana y accesible es la que mejor conocemos.'
                    ] }
                />
                <div className="contenido_ops">
                    {CostaHabitats.map((habitat) => (  
                        <Element link={ habitat.link } nombre={ habitat.nombre } key={ habitat.nombre }/>
                    ))}
                </div>
                <div className="contenido_back"><Link to="/habitats"></Link><img src="/images/tierra/arrow.svg" alt="Flecha atrás"/>Hábitats</div>
            </div>

            <Footer/>
        </>
    )
}