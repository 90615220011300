import { NavbarNew, SidebarNew } from "../components/Navigation";
import { HeaderVideo } from "../components/Header";
import Footer from "../components/Footer";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

import { Link } from "react-router-dom"

import HomeSlider from "../components/HomeSlider";
import "./../styles/homeNew.css";

gsap.registerPlugin(ScrollTrigger);

export default function Home() {
    const windowSize = useRef([
        window.innerWidth,
        window.innerHeight,
    ]);
    console.log(windowSize.current[0]);

    { /* Movimiento tiburón */ }
    const tiburon = useRef(null);
    const timeline_tiburon = useRef();
    useEffect(() => {
        timeline_tiburon.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.vidaTiburon',
                markers: false,
                start: '0% bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_tiburon.current.from(tiburon.current, {x: -100, y: 100, duration: 3,});
    });

    { /* Movimiento medusa */ }
    const medusa = useRef(null);
    const timeline_medusa = useRef();
    useEffect(() => {
        timeline_medusa.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.grandiosas__medusa',
                markers: false,
                start: '0% bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_medusa.current.from(medusa.current, {x: -20, y: 180, duration: 3,});
    });

    { /* Movimiento guacamaya */ }
    const guacamaya = useRef(null);
    const timeline_guacamaya = useRef();
    useEffect(() => {
        timeline_guacamaya.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.grandiosas__guacamaya',
                markers: false,
                start: '0% bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_guacamaya.current.from(guacamaya.current, {x: 20, y: -150, duration: 3,});
    });

    { /* Movimiento guacamaya */ }
    const foca = useRef(null);
    const timeline_foca = useRef();
    useEffect(() => {
        timeline_foca.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.planifica_des',
                markers: false,
                start: '0% bottom',
                end: '0 0',
                scrub: true,
            },
        });
        timeline_foca.current.to(foca.current, {x: 0, y:24, duration: 3,});
    });

    {/* Movimiento pinguino */}
    const pinguino = useRef(null);
    const timeline_pinguino = useRef();
    useEffect(() => {
        timeline_pinguino.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.planifica_pinguino',
                markers: false,
                start: '0% bottom',
                end: '0 0',
                scrub: true,
            },
        });
        timeline_pinguino.current.to(pinguino.current, {x:20, y:48, duration: 3,});
    });

    {/* Aparecer botones */}
    const flotantes = useRef(null);
    const timeline_flotantes = useRef();
    useEffect(() => {
        timeline_flotantes.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.vida',
                markers: false,
                start: '50% bottom',
                end: '0 0',
                scrub: true,
            },
        });
        timeline_flotantes.current.from(flotantes.current, {opacity: 0, duration: .1});
    });


    return (
        <>  
            <NavbarNew engLink={ "/eng/" }/>
            <SidebarNew engLink={ "/eng/" }/>
            <div className="header video home_header">
                <div className="header__shadow"></div>
                <video poster={ windowSize.current[0] >= 600 ? "/video/video_poster.webp" : "/video/video_poster_cuadrado.webp" } src={ windowSize.current[0] >= 600 ? "/video/home2.mp4" : "/video/home_cuadrado.mp4" } muted autoPlay loop playsInline className="header__bg"/>
            </div>
            {/*<HeaderVideo ref={ video } routeName="home" altName="Gran Acuario Mazatlán Render" classes="home_header"/> */}

            {/* Botón flotante */}
            <div ref={ flotantes } className="flotantes">
                <a href="https://api.whatsapp.com/send?phone=%2B526691390134" title="Whatsapp" className="flotantes_wp">
                    <img src="/images/whatsapp.png" alt="Whatsapp logo" title="Whatsapp logo"/>
                </a>
                <div className="separator"></div>
                <a href="https://tickets.granacuario.com/" title="Tickets" target="_blank" className="flotantes_tickets">
                    <img src="/images/tickets.png" alt="Tickets logo" title="Tickets logo"/>
                </a>
            </div>

            { /* SOMOS LLENOS DE VIDA */ }
            <div className="vida section">
                <img ref={ tiburon } className="vidaTiburon" src="/images/home/contenedores/tiburon.png" srcSet="/images/home/contenedores/tiburon.webp" alt="Tiburón" title="Tiburón"/>
                <div className="vida_bg_container">
                    <img className="vida__bg" src="/images/home/contenedores/background.png" srcSet="/images/home/contenedores/background.webp" alt="Background somos llenos de vida" title="Background somos llenos de vida" />
                </div>
                <div className="content">
                    <div className="vida_title">
                        <div className="vida_title__t1">SOMOS LLENOS DE VIDA</div>
                        <div className="vida_title__sep"></div>
                    </div>
                    <div className="vida_elements">
                        <div className="vida_element">
                            <Link to="/habitats" title="Habitats" className="vida_element_link"></Link>
                            <img src="/images/home/contenedores/habitats_icon.png" alt="Habitats" title="Habitats" className="vida_element__img" />
                            <div className="vida_element__t1">+ DE 30 HÁBITATS</div>
                            <div className="vida_element__t2">Tendras acceso a los más de 30 maravillos hábitats.</div>
                        </div>
                        <div className="vida_element">
                            <Link to="/habitats/mar/oceanica" title="Oceanica" className="vida_element_link"></Link>
                            <img src="/images/home/contenedores/ventana_icon.png" alt="Ventana oceánica" title="Ventana oceánica" className="vida_element__img" />
                            <div className="vida_element__t1">IMPRESIONANTE VENTANA OCEÁNICA</div>
                            <div className="vida_element__t2">La ventana oceánica más grande de LATAM.</div>
                        </div>
                        <div className="vida_element">
                            <Link to="/actividades" title="Actividades" className="vida_element_link"></Link>
                            <img src="/images/home/contenedores/actividades_icon.png" alt="Actividades" title="Actividades" className="vida_element__img" />
                            <div className="vida_element__t1">ACTIVIDADES</div>
                            <div className="vida_element__t2">Grandiosas actividades para toda la familia.</div>
                        </div>
                    </div>
                </div>
            </div>

            { /* GRAN ACUARIO MAZATLAN */ }
            <div className="acuario section">
                <div className="acuario_img">
                    <picture>
                        <source type="image/webp" srcSet="/images/home/contenedores/mujer_pinguino.webp" media="(min-width:600px)"/>
                        <source type="image/png" srcSet="/images/home/contenedores/mujer_pinguino.png" media="(min-width:600px)"/>
                        <img src="/images/home/contenedores/mujer_pinguino@600.png" srcSet="/images/home/contenedores/mujer_pinguino@600.webp" alt="Mujer con pinguino" title="Mujer con pinguino"/>
                    </picture>
                </div>
                <div className="acuario_des">
                    <div className="acuario_des__t1">Gran Acuario Mazatlán, es el más grande de México y más importante en Latinoamérica, dedicado al Mar de Cortés. </div>
                    <div className="acuario_des__t2">Con un enfoque en la educación, la investigación y divulgación del conocimiento, marcará un referente en los acuarios del mundo. Ubicado estratégicamente en el Parque Central, Gran Acuario Mazatlán abre todos los días.</div>
                    <div className="acuario_des__t3">HORARIO</div>
                    <div className="acuario_des__t4"><img src="/images/home/contenedores/horario_icon.png" alt="Reloj icon" title="Reloj icon"/> 10:00 AM  - 06:00 PM</div>
                    <div className="acuario_des__t3">CIERRE DE TAQUILLA</div>
                    <div className="acuario_des__t4"><img src="/images/home/contenedores/horario_icon.png" alt="Reloj icon" title="Reloj icon"/> 04:00 PM</div>
                </div>
            </div>

            { /* ACTIVIDADES */ }
            <div className="grandiosas section">
                <picture>
                    <source type="image/webp" srcSet="/images/home/contenedores/medusa.webp" media="(min-width:600px)"/>
                    <source type="image/png" srcSet="/images/home/contenedores/medusa.png" media="(min-width:600px)"/>
                    <img ref={ medusa } src="/images/home/contenedores/medusa@200.png" srcSet="/images/home/contenedores/medusa@200.webp" alt="Medusa" title="Medusa" className="grandiosas__medusa"/>
                </picture>
                <img ref={ guacamaya } className="grandiosas__guacamaya" src="/images/home/contenedores/guacamaya.png" srcSet="/images/home/contenedores/guacamaya.webp" alt="Guacamaya" title="Guacamaya"/>
                <div className="content">
                    <div className="grandiosas_title">
                        <div className="grandiosas_title__t1">ACTIVIDADES</div>
                        <div className="grandiosas_title__t2">Grandiosas actividades para disfrutar en el Gran Acuario, para toda la familia.</div>
                    </div>
                    <HomeSlider/>
                </div>
            </div>

            { /* PLANIFICA TU VISITA */ }
            <div className="planifica section">
                <img className="planifica__bg" src="/images/home/contenedores/background2.svg" alt="Tramado oficial" title="Tramado oficial"/>
                <div className="content">
                    <div className="planifica_des">
                        <div className="planifica_des_container">
                            <div className="planifica_des__t1">PLANIFICA TU VISITA</div>
                            <div className="planifica_des__sep"></div>
                            <div className="planifica_des__t2">¡No te pierdas nada! Descubre todo lo que te ofrece Gran Acuario Mazatlán: presentaciones de animales, más de 30 hábitats, una densa vegetación y mucho más.</div>
                            <Link to="/visita" title="Visita" className="planifica_des__btn">MÁS INFORMACIÓN</Link>
                        </div>
                    </div>
                    <div className="planifica_img">
                        <img ref={ foca } className="foca" src="/images/home/contenedores/lobos_marinos_visita.png" srcSet="/images/home/contenedores/lobos_marinos_visita.webp" alt="Lobos marinos" title="Lobos marinos"/>
                    </div>
                </div>
                <div className="planifica_pinguino">
                    <img ref={ pinguino } className="pinguino" src="/images/home/contenedores/pinguino_visita.png" srcSet="/images/home/contenedores/pinguino_visita.webp" alt="Pinguino" title="Pinguino"/>
                </div>
            </div>

            <Footer/>

            <div className="seo_container">
                <h1>Gran Acuario Mazatlán: El Acuario Más Grande de Latinoamérica</h1>
                <h2>Descubre la Biodiversidad del Mar de Cortés</h2>
                <h2>Visítanos y Vive una Experiencia Inolvidable</h2>
                <h3>Hábitats Marinos de Clase Mundial</h3>
                <h3>Educación y Conservación para Toda la Familia</h3>
                <h2>Compra tus Boletos Online y Planea tu Visita</h2>
            </div>
        </>
    )
}